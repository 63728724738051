import * as React from "react"

import Layout from "../../components/layout";
import PropertyDetailsComponent from "../../components/PropertyDetails";

const PropertyDetails = () => {
  return (
    <Layout title="get-offer-no-background">
      <PropertyDetailsComponent />
    </Layout>
  );
}

export default PropertyDetails;