import * as React from "react"
import { useEffect, useReducer } from "react";
import { navigate } from "gatsby"

import { getStorage, setStorage } from "../utils";
import request from "../services/request";
import {
  amountJson,
  conditionJson,
  wantingJson,
  yesNoOption,
  reasonJson,
  timeJson,
  OFFER_THANKS_SCREEN
} from "../constant";
import ButtonField from "./common/form/ButtonField";
import SelectField from "./common/form/SelectField";

const initialState = {
  currently_listed: "",
  require_repairs: "",
  current_condition: "",
  urgency: "",
  asking_price: "",
  reason_to_sell: "",
  time_to_call: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case action.type:
      return { ...state, [action.type]: action.value }
    default:
      throw new Error()
  }
}

const PropertyDetails = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (getStorage("formData")) {
      const {
        currently_listed,
        require_repairs,
        current_condition,
        urgency,
        asking_price,
        reason_to_sell,
        time_to_call,
      } = getStorage("formData")
      let obj = {
        currently_listed,
        require_repairs,
        current_condition,
        urgency,
        asking_price,
        reason_to_sell,
        time_to_call,
      }
      Object.keys(obj).map(keyName =>
        dispatch({
          type: keyName,
          value: obj[keyName] === null ? initialState[keyName] : obj[keyName],
        })
      )
    }
  }, [])

  const onSubmit = e => {
    e.preventDefault()
    let initialVal = getStorage("formData")
    let finalVal = { ...initialVal, ...state }
    let data = {
      email: finalVal.email,
      clientId: window.ga.getAll()[0].get("clientId"),
      address: finalVal.address,
      first_name: finalVal.first_name,
      last_name: finalVal.last_name,
      ...state,
    }
    request({ url: "ov5uppx", data })
      .then(() => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "extra_details_submit",
          dtL_extra_details_submit: {
            email: data.email,
            asking_price: data.asking_price,
            currently_listed: data.currently_listed,
            require_repairs: data.require_repairs,
            current_condition: data.current_condition,
            urgency: data.urgency,
            time_to_call: data.time_to_call,
            reason_to_sell: data.reason_to_sell,
            branch: process.env.GATSBY_BRANCH
          },
        })
        navigate(OFFER_THANKS_SCREEN)
        setStorage("formData", finalVal)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  const onValueChange = (e, type) => {
    dispatch({ type, value: e.target.value })
  }

  return (
    <form className="needs-validation propertyDetailContainer" onSubmit={e => onSubmit(e)}>
      <div className="module-property-details mb-5">
        <div className="container">
          <div className="form">
            <div className="text-center font-weight-bold mt-2 pl-4 pr-4 title">
              Our no-haggle offer comes to you!
            </div>
            <div className="text-center mt-3 pl-4 pr-4 subTitle ">
              Let's verify some facts about your home and our team of
              experienced appraisers will get you an offer in minutes!
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6 form-group pr-sm-2">
                <SelectField
                  label={"Is your house currently listed with a realtor?"}
                  id="listed"
                  options={yesNoOption}
                  className="list-select"
                  textClassName="text-dark"
                  value={state.currently_listed}
                  onChange={(e) => onValueChange(e, "currently_listed")}
                />
              </div>
              <div className="col-12 col-sm-6 form-group pl-sm-2">
                <SelectField
                  label={"Does your home currently require repairs?"}
                  id="repairs"
                  options={yesNoOption}
                  className="list-select"
                  textClassName="text-dark"
                  value={state.require_repairs}
                  onChange={(e) => onValueChange(e, "require_repairs")}
                />
              </div>
              <div className="col-12 col-sm-6 form-group pr-sm-2">
                <SelectField
                  label={"What is the current condition of your home?"}
                  id="condition"
                  options={conditionJson}
                  className="list-select"
                  textClassName="text-dark"
                  value={state.current_condition}
                  onChange={(e) => onValueChange(e, "current_condition")}
                />
              </div>
              <div className="col-12 col-sm-6 form-group pl-sm-2">
                <SelectField
                  label={"How fast are you wanting to sell?"}
                  id="wanting"
                  options={wantingJson}
                  className="list-select"
                  textClassName="text-dark"
                  value={state.urgency}
                  onChange={(e) => onValueChange(e, "urgency")}
                />
              </div>
              <div className="col-12 col-sm-6 form-group pr-sm-2">
                <SelectField
                  label={"Please select your asking price"}
                  id="amount"
                  options={amountJson}
                  className="list-select"
                  textClassName="text-dark"
                  value={state.asking_price}
                  onChange={(e) => onValueChange(e, "asking_price")}
                />
              </div>
              <div className="col-sm-6 col-12 form-group pl-sm-2">
                <SelectField
                  label={"Reason you want to sell?"}
                  id="reason_to_sell"
                  options={reasonJson}
                  className="list-select"
                  textClassName="text-dark"
                  value={state.reason_to_sell}
                  onChange={e => onValueChange(e, "reason_to_sell")}
                />
              </div>
              <div className="col-sm-6 col-12 form-group pr-sm-2">
                <SelectField
                  label={"When is the best time to contact you?"}
                  id="timetocontact"
                  options={timeJson}
                  className="list-select"
                  textClassName="text-dark"
                  value={state.time_to_call}
                  onChange={e => onValueChange(e, "time_to_call")}
                />
              </div>
            </div>
            <div className="row mt-2 buttons">
              <div className="col-6 pr-2">
                <ButtonField
                  className="btn-outline-secondary btnText rounded-0 btn-lg btn-block"
                  name="Back"
                  onClick={() => navigate(-1)}
                />
              </div>
              <div className="col-6 pl-2">
                <button
                  type="submit"
                  className="btn btn-lg rounded-0 text-white btnHoverBackground btnHover btnText btn-block bgYellow"
                >
                  Get My Offer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PropertyDetails;
